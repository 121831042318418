import React from 'react';
import classNames from 'classnames';

import './Input.css';

const Input = ({ ...props }) => (
  <div
    className={classNames('c-input-container', props.className, {
      'c-input-container--horizontal': props.horizontal,
      'c-input-container--mobile-only-label': props.mobileOnlyLabel,
    })}
  >
    <label htmlFor={props.name} className="font-bold c-input-container__label">
      {props.label}
      {props.asterisk && <span className="c-input-container__asterisk">*</span>}
    </label>
    {props.optionalLabel && (
      <span className="c-input-container__optional">Optional</span>
    )}
    <div className="flex items-center w-full">
      <input
        className={classNames('c-input', {
          'c-input--optional': props.optionalLabel,
        })}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        required={!props.optional && !props.optionalLabel}
      />
      {props.meta && (
        <div className="c-input-container__meta">{props.meta}</div>
      )}
    </div>
  </div>
);

export default Input;
