import React from 'react';
import classNames from 'classnames';

import './Input.css';

const TextArea = ({ ...props }) => (
  <div
    className={classNames('c-input-container', props.className, {
      'c-input-container--horizontal c-input-container--textarea-horizontal':
        props.horizontal,
    })}
  >
    <label htmlFor={props.name} className="font-bold c-input-container__label">
      {props.label}
    </label>
    <textarea
      className="c-input c-input--textarea"
      name={props.name}
      placeholder={props.placeholder}
      required={!props.optional}
    ></textarea>
  </div>
);

export default TextArea;
