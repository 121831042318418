import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import ApplyForm from '../components/ApplyForm/ApplyForm';

const ApplyNowPage = () => (
  <Layout>
    <Seo
      title="Rental Application"
      description="Application for rental accommodation"
    />
    <Hero
      extend
      background={
        <StaticImage
          src="../images/large-hero-bg.jpg"
          layout="fullWidth"
          placeholder="blurred"
          quality="90"
          alt=""
        />
      }
    >
      <h1 className="h-page-hero-title">
        Application for Rental Accommodation
      </h1>
      <p className="mt-3 md:text-lg">
        The Landlord Acknowledges the Confidentiality of This Document
      </p>
    </Hero>
    <ApplyForm />
  </Layout>
);

export default ApplyNowPage;
