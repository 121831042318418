import React from 'react';
import classNames from 'classnames';

import './Input.css';

import ChevronIcon from '../Icons/24/ChevronDownIcon24';

const Select = ({ ...props }) => (
  <div
    className={classNames('c-input-container', props.className, {
      'c-input-container--horizontal': props.horizontal,
    })}
  >
    <label htmlFor={props.name} className="font-bold c-input-container__label">
      {props.label}
      {props.asterisk && <span className="c-input-container__asterisk">*</span>}
    </label>
    <ChevronIcon className="fill-brand-medium c-input-container__chevron" />
    <select
      className="c-input c-select"
      name={props.name}
      required={!props.optional}
      defaultValue=""
      onChange={props.onChange}
    >
      <option value="" disabled>
        {props.placeholder}
      </option>
      {props.children}
    </select>
  </div>
);

export default Select;
