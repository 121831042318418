import React from 'react';

import './ApplyForm.css';

import Form from '../Common/Form/Form';
import Input from '../Common/Input/Input';
import BuildingSelector from '../Common/BuildingSelector/BuildingSelector';
import Select from '../Common/Input/Select';
import TextArea from '../Common/Input/TextArea';
import Checkbox from '../Common/Checkbox/Checkbox';
import Button from '../Common/Button/Button';

const ApplyForm = () => (
  <div className="l-container l-container--no-mobile-gutter">
    <section className="c-apply-form">
      <Form
        className="w-full"
        formName="application-form"
        successTitle="Application sent"
        successMessage="Thank you for your application. We will get back to you as soon as possible."
        errorTitle="Application not sent"
        errorMessage="Sorry your application has not been sent. Please try again."
      >
        <input type="hidden" name="form-name" value="application-form" />

        <div className="c-apply-form-section-header">
          <h2 className="text-xl font-bold">Accommodation Information</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <BuildingSelector asterisks />
            <Select
              optional
              horizontal
              className="mb-5"
              label="Unit Type"
              name="UnitType"
              placeholder="Select unit type"
            >
              <option value="1 Bedroom">1 Bedroom</option>
              <option value="2 Bedroom">2 Bedroom</option>
              <option value="3 Bedroom">3 Bedroom</option>
              <option value="Other">Other</option>
            </Select>
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Date Requested"
              type="date"
              name="DateRequested"
              placeholder="Select date"
            />
          </div>
        </div>

        <div>
          <h3 className="mt-8 font-bold lg:mt-6">
            Names of all people to occupy this property
          </h3>
          <Input
            asterisk
            horizontal
            className="mt-6 lg:mt-5"
            label="Adults"
            type="text"
            name="Adults"
            placeholder="Enter all adult names…"
          />
          <Input
            optional
            horizontal
            className="mt-5"
            label="Children"
            type="text"
            name="Children"
            placeholder="Enter all child names…"
          />
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Personal Information</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              optional
              horizontal
              className="mt-5 mb-5"
              label="Full Name"
              type="text"
              name="ApplicantFullName"
              placeholder="Applicant full name…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Date of Birth"
              type="date"
              name="ApplicantDateOfBirth"
              placeholder="Applicant date of birth"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Phone Number"
              type="tel"
              name="ApplicantPhoneNumber"
              placeholder="Applicant phone number…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Email Address"
              type="email"
              name="ApplicantEmailAddress"
              placeholder="Applicant email address…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Social Insurance Number"
              type="text"
              name="ApplicantSocialInsuranceNumber"
              placeholder="Applicant social insurance number…"
            />
            <Input
              optional
              horizontal
              label="Driver’s License Number"
              type="text"
              name="ApplicantDriversLicenseNumber"
              placeholder="Applicant driver’s license number…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Full Name"
              type="text"
              name="Co-ApplicantFullName"
              placeholder="Co-applicant full name…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Date of Birth"
              type="date"
              name="Co-ApplicantDateOfBirth"
              placeholder="Co-applicant date of birth"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Phone Number"
              type="tel"
              name="Co-ApplicantPhoneNumber"
              placeholder="Co-applicant phone number…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Email Address"
              type="email"
              name="Co-ApplicantEmailAddress"
              placeholder="Co-applicant email address…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Social Insurance Number"
              type="text"
              name="Co-ApplicantSocialInsuranceNumber"
              placeholder="Co-applicant social insurance number…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Driver’s License Number"
              type="text"
              name="Co-ApplicantDriversLicenseNumber"
              placeholder="Co-applicant driver’s license number…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Present Address</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              optional
              horizontal
              className="mt-5 mb-5"
              label="Street Address"
              type="text"
              name="ApplicantPresentStreetAddress"
              placeholder="Applicant street address…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="City"
              type="text"
              name="ApplicantPresentCity"
              placeholder="Applicant city…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Province"
              type="text"
              name="ApplicantPresentProvince"
              placeholder="Applicant province…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Country"
              type="text"
              name="ApplicantPresentCountry"
              placeholder="Applicant country…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Postal Code"
              type="text"
              name="ApplicantPresentPostalCode"
              placeholder="Applicant postal code…"
            />
            <Input
              optional
              horizontal
              label="Length of Tenancy"
              type="text"
              name="ApplicantPresentLengthofTenancy"
              placeholder="Applicant length of tenancy…"
              meta="Months"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Street Address"
              type="text"
              name="Co-ApplicantPresentStreetAddress"
              placeholder="Co-applicant street address…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="City"
              type="text"
              name="Co-ApplicantPresentCity"
              placeholder="Co-applicant city…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Province"
              type="text"
              name="Co-ApplicantPresentProvince"
              placeholder="Co-applicant province…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Country"
              type="text"
              name="Co-ApplicantPresentCountry"
              placeholder="Co-applicant country…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Postal Code"
              type="text"
              name="Co-ApplicantPresentPostalCode"
              placeholder="Co-applicant postal code…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Length of Tenancy"
              type="text"
              name="Co-ApplicantPresentLengthofTenancy"
              placeholder="Co-applicant length of tenancy…"
              meta="Months"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Previous Address</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              optional
              horizontal
              className="mt-5 mb-5"
              label="Street Address"
              type="text"
              name="ApplicantPreviousStreetAddress"
              placeholder="Applicant street address…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="City"
              type="text"
              name="ApplicantPreviousCity"
              placeholder="Applicant city…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Province"
              type="text"
              name="ApplicantPreviousProvince"
              placeholder="Applicant province…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Country"
              type="text"
              name="ApplicantPreviousCountry"
              placeholder="Applicant country…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Postal Code"
              type="text"
              name="ApplicantPreviousPostalCode"
              placeholder="Applicant postal code…"
            />
            <Input
              optional
              horizontal
              label="Length of Tenancy"
              type="text"
              name="ApplicantPreviousLengthofTenancy"
              placeholder="Applicant length of tenancy…"
              meta="Months"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Street Address"
              type="text"
              name="Co-ApplicantPreviousStreetAddress"
              placeholder="Co-applicant street address…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="City"
              type="text"
              name="Co-ApplicantPreviousCity"
              placeholder="Co-applicant city…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Province"
              type="text"
              name="Co-ApplicantPreviousProvince"
              placeholder="Co-applicant province…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Country"
              type="text"
              name="Co-ApplicantPreviousCountry"
              placeholder="Co-applicant country…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Postal Code"
              type="text"
              name="Co-ApplicantPreviousPostalCode"
              placeholder="Co-applicant postal code…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Length of Tenancy"
              type="text"
              name="Co-ApplicantPreviousLengthofTenancy"
              placeholder="Co-applicant length of tenancy…"
              meta="Months"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Current Employment Information</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              asterisk
              horizontal
              className="mt-5 mb-5"
              label="Employer"
              type="text"
              name="ApplicantCurrentEmployer"
              placeholder="Applicant employer…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Occupation"
              type="text"
              name="ApplicantCurrentOccupation"
              placeholder="Applicant occupation…"
            />
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Length of Employment"
              type="text"
              name="ApplicantCurrentLengthofEmployment"
              placeholder="Applicant length of employment…"
              meta="Months"
            />
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Supervisor Name"
              type="text"
              name="ApplicantCurrentSupervisorName"
              placeholder="Applicant supervisor name…"
            />
            <Input
              asterisk
              horizontal
              label="Supervisor Phone"
              type="tel"
              name="ApplicantCurrentSupervisorPhone"
              placeholder="Applicant supervisor phone…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Employer"
              type="text"
              name="Co-ApplicantCurrentEmployer"
              placeholder="Co-applicant employer…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Occupation"
              type="text"
              name="Co-ApplicantCurrentOccupation"
              placeholder="Co-applicant occupation…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Length of Employment"
              type="text"
              name="Co-ApplicantCurrentLengthofEmployment"
              placeholder="Co-applicant length of employment…"
              meta="Months"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Supervisor Name"
              type="text"
              name="Co-ApplicantCurrentSupervisorName"
              placeholder="Co-applicant supervisor name…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Supervisor Phone"
              type="tel"
              name="Co-ApplicantCurrentSupervisorPhone"
              placeholder="Co-applicant supervisor phone…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Previous Employment Information</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              optional
              horizontal
              className="mt-5 mb-5"
              label="Employer"
              type="text"
              name="ApplicantPreviousEmployer"
              placeholder="Applicant employer…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Occupation"
              type="text"
              name="ApplicantPreviousOccupation"
              placeholder="Applicant occupation…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Length of Employment"
              type="text"
              name="ApplicantPreviousLengthofEmployment"
              placeholder="Applicant length of employment…"
              meta="Months"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Supervisor Name"
              type="text"
              name="ApplicantPreviousSupervisorName"
              placeholder="Applicant supervisor name…"
            />
            <Input
              optional
              horizontal
              label="Supervisor Phone"
              type="tel"
              name="ApplicantPreviousSupervisorPhone"
              placeholder="Applicant supervisor phone…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Employer"
              type="text"
              name="Co-ApplicantPreviousEmployer"
              placeholder="Co-applicant employer…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Occupation"
              type="text"
              name="Co-ApplicantPreviousOccupation"
              placeholder="Co-applicant occupation…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Length of Employment"
              type="text"
              name="Co-ApplicantPreviousLengthofEmployment"
              placeholder="Co-applicant length of employment…"
              meta="Months"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Supervisor Name"
              type="text"
              name="Co-ApplicantPreviousSupervisorName"
              placeholder="Co-applicant supervisor name…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Supervisor Phone"
              type="tel"
              name="Co-ApplicantPreviousSupervisorPhone"
              placeholder="Co-applicant supervisor phone…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Credit Information</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              asterisk
              horizontal
              className="mt-5 mb-5"
              label="Income"
              type="text"
              name="ApplicantIncome"
              placeholder="Applicant income…"
              meta={
                <span>
                  Per month
                  <br />
                  <span className="whitespace-nowrap">(net after tax)</span>
                </span>
              }
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Bank Name"
              type="text"
              name="ApplicantBankName"
              placeholder="Applicant bank name…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Branch Name"
              type="text"
              name="ApplicantBranchName"
              placeholder="Applicant branch name…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Income"
              type="text"
              name="Co-ApplicantIncome"
              placeholder="Co-applicant income…"
              meta={
                <span>
                  Per month
                  <br />
                  <span className="whitespace-nowrap">(net after tax)</span>
                </span>
              }
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Bank Name"
              type="text"
              name="Co-ApplicantBankName"
              placeholder="Co-applicant bank name…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Branch Name"
              type="text"
              name="Co-ApplicantBranchName"
              placeholder="Co-applicant branch name…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Rental History</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              asterisk
              horizontal
              className="mt-5 mb-5"
              label="Current Landlord"
              type="text"
              name="ApplicantCurrentLandlord"
              placeholder="Applicant current landlord…"
            />
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Current Landlord Phone"
              type="tel"
              name="ApplicantCurrentLandlordPhone"
              placeholder="Applicant current landlord phone…"
            />
            <Input
              optional
              horizontal
              className="mb-5"
              label="Previous Landlord"
              type="text"
              name="ApplicantPreviousLandlord"
              placeholder="Applicant previous landlord…"
            />
            <Input
              optional
              horizontal
              label="Previous Landlord Phone"
              type="tel"
              name="ApplicantPreviousLandlordPhone"
              placeholder="Applicant previous landlord phone…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Current Landlord"
              type="text"
              name="Co-ApplicantCurrentLandlord"
              placeholder="Co-applicant current landlord…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Current Landlord Phone"
              type="tel"
              name="Co-ApplicantCurrentLandlordPhone"
              placeholder="Co-applicant current landlord phone…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Previous Landlord"
              type="text"
              name="Co-ApplicantPreviousLandlord"
              placeholder="Co-applicant previous landlord…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Previous Landlord Phone"
              type="tel"
              name="Co-ApplicantPreviousLandlordPhone"
              placeholder="Co-applicant previous landlord phone…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Emergency Contact</h2>
        </div>

        <div className="c-apply-form-grid">
          <div>
            <div className="c-applicant-badge">Applicant</div>
            <Input
              asterisk
              horizontal
              className="mt-5 mb-5"
              label="Name"
              type="text"
              name="ApplicantEmergencyContactName"
              placeholder="Applicant emergency contact name…"
            />
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Address"
              type="text"
              name="ApplicantEmergencyContactAddress"
              placeholder="Applicant emergency contact address…"
            />
            <Input
              asterisk
              horizontal
              className="mb-5"
              label="Phone Number"
              type="tel"
              name="ApplicantEmergencyContactPhone"
              placeholder="Applicant emergency contact phone…"
            />
            <Input
              asterisk
              horizontal
              label="Relationship"
              type="text"
              name="ApplicantEmergencyContactRelationship"
              placeholder="Applicant emergency contact relationship…"
            />
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="c-applicant-badge c-applicant-badge--co-applicant">
              Co-Applicant
            </div>
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mt-5 mb-5"
              label="Name"
              type="text"
              name="Co-ApplicantEmergencyContactName"
              placeholder="Co-applicant emergency contact name…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Address"
              type="text"
              name="Co-ApplicantEmergencyContactAddress"
              placeholder="Co-applicant emergency contact address…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              className="mb-5"
              label="Phone Number"
              type="tel"
              name="Co-ApplicantEmergencyContactPhone"
              placeholder="Co-applicant emergency contact phone…"
            />
            <Input
              optional
              horizontal
              mobileOnlyLabel
              label="Relationship"
              type="text"
              name="Co-ApplicantEmergencyContactRelationship"
              placeholder="Co-applicant emergency contact relationship…"
            />
          </div>
        </div>

        <div className="c-apply-form-section-header mt-12 lg:mt-16">
          <h2 className="text-xl font-bold">Additional Information</h2>
        </div>

        <TextArea
          optional
          horizontal
          label="Message"
          name="AdditionalInformation"
          placeholder="Enter any additional information…"
        />

        <p className="mt-12 mb-8 lg:mt-16">
          <strong className="font-bold">
            All statements that I have made in this application are true.
          </strong>{' '}
          By signing this application,{' '}
          <strong className="font-bold">ALL</strong> personal information is
          consensually given for use by us or our appointed agents in respect to
          your application, subsequent tenancy, or on- file records in
          accordance to The Personal Information Protection and Electronic
          Documents Act (PIPEDA 2004) This is to include and extend to the
          gathering and consent to access of account information and status for
          ALL utility companies that the Tenant may enter into contracts with
          for the duration and for periods after the termination of the tenancy
          to ensure accounts are in good and current standing during and at the
          completion of the lease period.
        </p>

        <Checkbox required name="consent" label=" I agree to the terms above" />

        <Button className="mt-8" submit label="Submit Application Form" />
      </Form>
    </section>
  </div>
);

export default ApplyForm;
