import React from 'react';

import './Checkbox.css';

const Checkbox = ({ ...props }) => (
  <label className="flex">
    <span className="c-checkbox">
      <input type="checkbox" name={props.name} required={props.required} />
      <span className="c-checkbox__elm">
        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            d="M1.73 12.91l6.37 6.37L22.79 4.59"
          />
        </svg>
      </span>
    </span>
    <span className="font-bold ml-4">{props.label}</span>
  </label>
);

export default Checkbox;
