import React from 'react';
import classNames from 'classnames';

import './MessageState.css';

const MessageState = ({ ...props }) => (
  <div
    className={classNames('c-message-state', {
      'c-message-state--show': props.show,
    })}
  >
    {!props.error && (
      <svg width="80" height="80">
        <g fill="none" fillRule="evenodd">
          <circle cx="40" cy="40" fill="#8c734b" r="40" />
          <path
            fill="#fff"
            fillRule="nonzero"
            d="m57 29.82-24 24-11-11 2.82-2.82 8.18 8.16 21.18-21.16z"
          />
        </g>
      </svg>
    )}
    {props.error && (
      <svg width="80" height="80">
        <g fill="none" fillRule="evenodd">
          <circle cx="40" cy="40" fill="#e54848" r="40" />
          <path
            fill="#fff"
            fillRule="nonzero"
            d="m54 28.82-2.82-2.82-11.18 11.18-11.18-11.18-2.82 2.82 11.18 11.18-11.18 11.18 2.82 2.82 11.18-11.18 11.18 11.18 2.82-2.82-11.18-11.18z"
          />
        </g>
      </svg>
    )}
    <span className="block text-2xl font-bold mt-6">{props.title}</span>
    <p className="text-lg text-brand-medium mt-3">{props.message}</p>
  </div>
);

export default MessageState;
