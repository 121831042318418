import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import MessageState from '../MessageState/MessageState';

const Form = ({ ...props }) => {
  const [formState, setFormState] = useState({
    processing: false,
    submitted: false,
    success: false,
    error: false,
  });

  useEffect(() => {
    setFormState({ submitted: props.reset });
  }, [props.reset]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState({ processing: true });
    const formElm = e.target;
    const formData = new FormData(formElm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormState({
          processing: false,
          submitted: true,
          success: true,
        });
        formElm.reset();
      })
      .catch(() => {
        setFormState({
          processing: false,
          submitted: true,
          error: true,
        });
      });
  };

  return (
    <>
      <MessageState
        show={formState.success}
        title={props.successTitle}
        message={props.successMessage}
      />
      <MessageState
        error
        show={formState.error}
        title={props.errorTitle}
        message={props.errorMessage}
      />

      {props.formTitle && (
        <h2
          className={classNames('h-h3 mb-6 md:mb-8', {
            hidden: formState.submitted,
          })}
        >
          Send us a message
        </h2>
      )}
      <form
        className={classNames(props.className, {
          'pointer-events-none opacity-40': formState.processing,
          hidden: formState.submitted,
        })}
        id={props.formName}
        name={props.formName}
        netlify-honeypot="bot-field"
        method="post"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        {props.children}
      </form>
    </>
  );
};

export default Form;
